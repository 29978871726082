import classNames from 'classnames';
import i18next from 'i18next';
import React, { InputHTMLAttributes, FunctionComponent } from 'react';

interface OwnProps {
  label: string;
  secondary?: boolean;
  questionNumber?: number;
}

export type Props = OwnProps &
  Pick<
    InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'disabled' | 'value' | 'name' | 'checked'
  >;

const CheckableButton: FunctionComponent<Props> = ({
  label,
  disabled,
  value,
  name,
  checked,
  onChange,
  secondary,
  questionNumber,
}) => (
  <label className="checkable-button-wrapper">
    {questionNumber === 0 && value === 1 && (
      <div className="extra-header align-left">
        <div>{i18next.t<string>('Not competent heading')}</div>
        <div>{i18next.t<string>('(unskilled)')}</div>
      </div>
    )}
    {questionNumber === 0 && value === 5 && (
      <div className="extra-header align-center">
        <div>{i18next.t<string>('Competent heading')}</div>
        <div>{i18next.t<string>('(skilled)')}</div>
      </div>
    )}
    {questionNumber === 0 && value === 10 && (
      <div className="extra-header align-right">
        <div>{i18next.t<string>('Exceptional heading')}</div>
        <div>{i18next.t<string>('(expert)')}</div>
      </div>
    )}
    <input
      type="radio"
      disabled={disabled}
      onChange={onChange}
      value={value}
      name={name}
      checked={checked}
    />

    <div
      className={classNames('checkable-button', {
        secondary,
        disabled,
      })}
      tabIndex={0}
    >
      {label}
    </div>
  </label>
);

export default CheckableButton;
